import { Link } from "gatsby";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { eraseCookie, getCookie, setCookie } from "./consent-utils";
import { setConsent } from "./consentSlice";
import { bannerActions, cookieBanner } from "./cookie-consent.module.scss";

const Cookies = { Consent: "ml_consent" };

const removeConsent = () => {
  eraseCookie(Cookies.Consent);
};

const CookieConsent = () => {
  const showBanner = useSelector((state) => state.consent.showBanner);
  const dispatch = useDispatch();

  const accept = () => {
    setCookie(Cookies.Consent, "true", 365);
    dispatch(setConsent(true));
  };

  const decline = () => {
    removeConsent();
    dispatch(setConsent(false));
  };

  useEffect(() => {
    const consent = getCookie(Cookies.Consent) === "true";
    if (consent) {
      dispatch(setConsent(consent));
    }
  }, []);

  return (
    <>
      {!showBanner ? null : (
        <div className={cookieBanner}>
          <span>
            <p>
              Wir verwenden Cookies und andere Services um Ihr Surferlebnis zu
              verbessern. Durch die Nutzung unserer Website stimmen Sie der
              Verwendung von Cookies zu. Weitere Informationen finden Sie in
              unserer <Link to="/datenschutz/">Datenschutzerklärung</Link>.
            </p>
          </span>
          <div className={bannerActions}>
            <button onClick={decline}>Ablehnen</button>
            <button onClick={accept}>Annehmen</button>
          </div>
        </div>
      )}
    </>
  );
};

export default CookieConsent;
