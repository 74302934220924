import "./src/styles/styles.scss";
import "./shared/styles/_breakpoints.scss";
import CookieConsent from "./shared/components/cookie-consent/cookie-consent";
import React from "react";
import store from "./store";
import { LocaleProvider } from "./src/components/locale-provider/locale-provider";
import { Provider } from "react-redux";

export const wrapPageElement = ({ element, props }) => {
  const previewMode = window.location.search?.includes("preview=true");
  return (
    <Provider store={store}>
      <LocaleProvider {...props}>
        {element}
        {previewMode ? null : <CookieConsent />}
      </LocaleProvider>
    </Provider>
  );
};
